.mixed {
  @include main;
  z-index: 25;
  &__image {
    background-size: cover;
    background-position: center;
    width: 50%;
    float: left;
    @include media($w768) {
      width: 100%;
      height: 300px;
    }
  }
  &__copy {
    background-color: $lead;
    width: 50%;
    min-height: 630px;
    color: $white;
    padding: 130px 0 115px;
    float: right;
    @include media($w1400) {
      min-height: 520px;
      padding: 90px 0;
    }
    @include media($w1024) {
      min-height: auto;
      padding: 50px 0;
    }
    @include media($w768) {
      width: 100%;
      padding: 40px 0;
    }
  }
  &__inner {
    width: 100%;
    max-width: 615px;
    color: $white;
    padding: 0 80px 0 160px;
    float: left;
    @include media($w1400) {
      padding: 0 80px;
    }
    @include media($w1024) {
      padding: 0 40px;
    }
    @include media($w768) {
      max-width: 100%;
      padding: 0 30px;
    }
    h6 {
      &.label {
        &:before {
          background-color: $white;
        }
      }
    }
    p {
      margin: 0 0 20px;
    }
    & > *:last-child {
      margin: 0;
    }
  }
  &__download {
    @include main;
    padding: 125px 5px 128px;
    @include media($w1400) {
      padding: 80px 5px 83px;
    }
    @include media($w1024) {
      padding: 50px 5px 35px;
    }
    @include media($w768) {
      padding: 38px 5px 25px;
    }
    @include media($w560) {
      padding: 33px 5px 20px;
    }
    ul {
      text-align: center;
      li {
        width: auto;
        font-size: 20px;
        line-height: 20px;
        color: $lead;
        margin: 0 25px;
        display: inline-block;
        float: none;
        @include media($w1400) {
          font-size: 18px;
          line-height: 18px;
          margin: 0 15px;
        }
        @include media($w1024) {
          margin: 0 15px 15px;
        }
        @include media($w768) {
          font-size: 16px;
          line-height: 16px;
        }
        &:first-child {
          font-weight: 600;
          @include media($w1024) {
            width: 100%;
            margin: 0 0 20px;
          }
          @include media($w560) {
            margin: 0 0 15px;
          }
        }
        a {
          color: $black;
          display: inline-block;
          padding: 0 0 0 42px;
          position: relative;
          &:before {
            @include translateY;
            background-image: url(../images/icon--pdf.png);
            width: 27px;
            height: 25px;
            display: block;
            position: absolute;
            top: calc(50% + 4px);
            left: 0;
          }
          &:hover {
            color: $lead;
          }
        }
      }
    }
  }
  &--mirror {
    .mixed__image {
      float: right;
    }
    .mixed__copy {
      float: left;
    }
    .mixed__inner {
      padding: 0 160px 0 0;
      float: right;
      @include media($w1400) {
        padding: 0 80px;
      }
      @include media($w1024) {
        padding: 0 40px;
      }
      @include media($w768) {
        padding: 0 30px;
      }
    }
  }
  &--bright {
    .mixed__copy {
      background-image: url(../images/mixed--bright--copy-bg.jpg);
      background-size: cover;
      background-position: center;
      @include media($w768) {
        background-image: none;
        background-color: rgba($form,0.4);
      }
    }
    .mixed__inner {
      color: $black;
      h6 {
        &.label {
          color: $lead;
          &:before {
            background-color: $lead;
          }
        }
      }
      h3 {
        &.section-title {
          color: $lead;
        }
      }
    }
  }
}
