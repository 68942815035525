input[type="text"],
input[type="email"],
textarea {
	@include main;
	@include transition;
	border: 0;
	border-radius: 0;
	background-color: $form;
	height: 30px;
	font-size: 14.5px;
	font-weight: 400;
	line-height: 19.5px;
	color: $black;
	margin: 0 0 10px;
	padding: 0 15px 1px;
	appearance: none;
	&:focus {
		background-color: $form;
	}
}

textarea {
	height: 140px;
	margin: 0 0 15px;
	padding: 10px 15px;
}

input[type="submit"] {
	appearance: none;
}

::-webkit-input-placeholder {
	color: $black !important;
	opacity: 1;
}

:-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

::-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $black !important;
	opacity: 1;
}

button {
	@include transition;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	appearance: none;
}

.btn {
	@include transition;
	border: 0;
	background-color: $lead;
	height: 36px;
	font-family: freight-sans-pro, sans-serif !important;
	font-size: 16px;
	font-weight: 600;
	line-height: 36px;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.05em;
	color: $white;
	cursor: pointer;
	margin: 20px 0 0;
	padding: 0 37px;
	float: right;
	@include media($w768) {
		width: 100%;
		font-size: 15px;
	}
	&:hover {
		background-color: lighten($lead,5%);
		color: $white;
	}
}

.wpcf7 {
	p {
		br {
			display: none;
		}
		label {
			font-size: 16.5px;
			font-weight: 500;
			line-height: 22.5px;
			margin: 0 0 5px;
			display: block;
			@include media($w768) {
	      font-size: 14.5px;
	      line-height: 19.5px;
	    }
		}
	}
	.wpcf7-list-item {
		@include main;
		margin: 0 0 3px;
		&.first {
			margin: 6px 0 3px;
		}
		label {
			cursor: pointer;
			margin: 0;
			.wpcf7-list-item-label {
				width: calc(100% - 28px);
				font-size: 16.5px;
				font-weight: 500;
				line-height: 22.5px;
				margin: -1px 0 0;
				float: right;
				@include media($w768) {
		      font-size: 14.5px;
		      line-height: 19.5px;
					margin: 0;
		    }
			}
		}
	}
}

.wpcf7-response-output {
	@include main;
	font-size: 15px;
	line-height: 20px;
	margin: 25px 0 0 !important;
	padding: 10px 20px 11px !important;
}
