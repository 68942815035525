.about {
  @include main;
  padding: 145px 0 130px;
  z-index: 25;
  @include media($w1400) {
    padding: 110px 0;
  }
  @include media($w1024) {
    padding: 80px 0;
  }
  @include media($w768) {
    padding: 50px 0;
  }
  @include media($w560) {
    padding: 40px 0;
  }
  &__col {
    width: calc(33.3334% - 40px);
    float: left;
    @include media($w1400) {
      width: calc(33.3334% - 20px);
    }
    @include media($w1024) {
      width: calc(40% - 15px);
    }
    @include media($w768) {
      width: 100%;
    }
    & + .about__col {
      margin: 0 0 0 60px;
      @include media($w1400) {
        margin: 0 0 0 30px;
      }
      @include media($w768) {
        margin: 0;
      }
    }
    &--desc {
      padding: 12px 0 0;
      @include media($w1400) {
        padding: 10px 0 0;
      }
      @include media($w1024) {
        width: calc(60% - 15px);
        padding: 8px 0 0;
      }
      @include media($w768) {
        width: 100%;
        padding: 0;
      }
      p {
        margin: 0 0 20px;
      }
      & > *:last-child {
        margin: 0;
      }
    }
    &--image {
      padding: 17px 0 0;
      @include media($w1024) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  }
  h6 {
    &.label {
      color: $lead;
      margin: 0 0 35px;
      @include media($w1400) {
        margin: 0 0 25px;
      }
      @include media($w768) {
        margin: 0 0 17px;
      }
      &:before {
        background-color: $lead;
      }
    }
  }
  h3 {
    &.section-title {
      color: $lead;
    }
  }
}
