.contact {
  @include main;
  padding: 130px 0;
  @include media($w1400) {
    padding: 100px 0;
  }
  @include media($w1024) {
    padding: 80px 0;
  }
  @include media($w768) {
    padding: 60px 0 40px;
  }
  @include media($w560) {
    padding: 50px 0 30px;
  }
  h6 {
    &.label {
      color: $lead;
      &:before {
        background-color: $lead;
      }
    }
  }
  h3 {
    &.section-title {
      color: $lead;
      margin: 0 0 110px;
      @include media($w1400) {
        margin: 0 0 90px;
      }
      @include media($w1024) {
        margin: 0 0 70px;
      }
      @include media($w768) {
        margin: 0 0 50px;
      }
      @include media($w560) {
        margin: 0 0 40px;
      }
    }
  }
  &__col {
    @include main;
    & + .contact__col {
      margin: 0 0 0 60px;
      @include media($w1400) {
        margin: 0 0 0 30px;
      }
      @include media($w1024) {
        margin: 0;
      }
    }
    &--first {
      width: calc(35% - 40px);
      @include media($w1400) {
        width: calc(35% - 20px);
      }
      @include media($w1024) {
        width: 100%;
      }
      @include media($w768) {
        width: calc(100% + 60px);
        margin: 0 -30px;
      }
    }
    &--second {
      width: calc(35% - 40px);
      padding: 42px 0 0;
      @include media($w1400) {
        width: calc(35% - 20px);
        padding: 35px 0 0;
      }
      @include media($w1024) {
        width: calc(50% - 15px);
      }
      @include media($w768) {
        width: 100%;
        padding: 30px 0 0;
      }
      p {
        font-size: 16.5px;
        font-weight: 500;
        line-height: 22.5px;
        margin: 0 0 20px;
        @include media($w768) {
          font-size: 14.5px;
          line-height: 19.5px;
        }
        strong {
          color: $lead;
        }
      }
      & > *:last-child {
        margin: 0;
      }
    }
    &--third {
      width: calc(30% - 40px);
      padding: 42px 0 0;
      @include media($w1400) {
        width: calc(30% - 20px);
        padding: 35px 0 0;
      }
      @include media($w1024) {
        width: calc(50% - 15px);
        margin: 0 0 0 30px !important;
      }
      @include media($w768) {
        width: 100%;
        margin: 0 !important;
        padding: 30px 0 0;
      }
    }
  }
  &__info {
    @include main;
    background-color: $lead;
    color: $white;
    padding: 42px 45px;
    @include media($w1400) {
      padding: 35px 30px;
    }
    p {
      font-size: 16.5px;
      line-height: 22.5px;
      opacity: 0.6;
      margin: 0 0 20px;
      @include media($w560) {
        font-size: 15px;
        line-height: 21px;
      }
      a {
        font-weight: 600;
        color: $white;
      }
    }
    & > *:last-child {
      margin: 0;
    }
  }
  &__map {
    @include main;
    height: 295px;
    @include media($w1400) {
      height: 270px;
    }
    @include media($w1024) {
      height: 250px;
    }
  }
}
