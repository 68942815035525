.events {
  @include main;
  background-image: url(../images/events--background.jpg);
  background-size: cover;
  background-position: center top;
  color: $white;
  padding: 130px 0;
  z-index: 25;
  @include media($w1400) {
    padding: 100px 0;
  }
  @include media($w1024) {
    padding: 80px 0;
  }
  @include media($w768) {
    padding: 60px 0;
  }
  @include media($w560) {
    padding: 50px 0;
  }
  h6 {
    &.label {
      &:before {
        background-color: $white;
      }
    }
  }
  h3 {
    &.section-title {
      margin: 0 0 110px;
      @include media($w1400) {
        margin: 0 0 90px;
      }
      @include media($w1024) {
        margin: 0 0 70px;
      }
      @include media($w768) {
        margin: 0 0 50px;
      }
      @include media($w560) {
        margin: 0 0 40px;
      }
    }
  }
  &__carousel {
    @include main;
    width: calc(100% - 110px);
    margin: 0 55px;
    @include media($w1024) {
      width: calc(100% - 90px);
      margin: 0 45px;
    }
    .owl-stage-outer {
      overflow: hidden;
    }
    .owl-nav {
      @include translateY;
      width: calc(100% + 110px);
      position: absolute;
      top: 38%;
      left: -55px;
      @include media($w1024) {
        width: calc(100% + 90px);
        left: -45px;
      }
      @include media($w560) {
        width: calc(100% + 80px);
        left: -40px;
      }
      .owl-prev,
      .owl-next {
        background-size: cover;
        width: 49px;
        height: 49px;
        @include media($w1400) {
          width: 40px;
          height: 40px;
        }
        @include media($w560) {
          width: 35px;
          height: 35px;
        }
      }
      .owl-prev {
        background-image: url(../images/icon--arrow-left.png);
        float: left;
      }
      .owl-next {
        background-image: url(../images/icon--arrow-right.png);
        float: right;
      }
    }
    .owl-dots {
      display: none;
    }
    .owl-item {
      float: left;
      .item {
        @include main;
        img {
          width: 100%;
          margin: 0 0 15px;
        }
        .year {
          font-size: 25px;
          line-height: 25px;
          font-weight: 600;
          margin: 0 0 6px;
          display: block;
          @include media($w1024) {
            font-size: 22px;
            line-height: 22px;
            margin: 0 0 5px;
          }
          @include media($w768) {
            font-size: 20px;
            line-height: 20px;
          }
          @include media($w560) {
            font-size: 18px;
            line-height: 18px;
          }
        }
        a {
          font-size: 18px;
          line-height: 18px;
          color: $white;
          display: block;
          @include media($w560) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
