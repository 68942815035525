* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
		content: '';
		display: none;
	}
}

::-moz-selection {
	background: $lead;
	color: $white;
}

::selection {
	background: $lead;
	color: $white;
}

body {
	background-color: $white;
	font-family: freight-sans-pro, sans-serif !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	color: $black;
	margin: 0;
	padding: 0;
}

strong, b {
	font-weight: 700;
}

a {
	@include transition;
	text-decoration: none;
	color: $black;
}

p {
	font-size: 16px;
	line-height: 21px;
	margin: 0;
	@include media($w768) {
		font-size: 14.5px;
		line-height: 19.5px;
	}
	strong, b {
		font-weight: 600;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	margin: 0;
}

h1 {
	font-size: 0px;
	line-height: 0px;
}

h2 {
	font-size: 0px;
	line-height: 0px;
}

h3 {
	font-size: 0px;
	line-height: 0px;
}

h4 {
	font-size: 0px;
	line-height: 0px;
}

h5 {
	font-size: 0px;
	line-height: 0px;
}

h6 {
	font-size: 0px;
	line-height: 0px;
}

ul {
	@include main;
	li {
		@include main;
		position: relative;
	}
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	@include main;
	overflow: hidden;
	padding: 175px 0 0;
	@include media($w1400) {
		padding: 130px 0 0;
	}
	@include media($w1024) {
		padding: 100px 0 0;
	}
	@include media($w768) {
		padding: 80px 0 0;
	}
	@include media($w560) {
		padding: 70px 0 0;
	}
}

.container {
	max-width: 1290px;
	margin: 0 auto;
	padding: 0 30px;
	position: relative;
}

.anchor {
	width: 100%;
	height: 1px;
	position: absolute;
	top: -130px;
	left: 0;
	@include media($w1400) {
		top: -100px;
	}
	@include media($w1024) {
		top: -90px;
	}
	@include media($w768) {
		top: -80px;
	}
	@include media($w560) {
		top: -70px;
	}
}

.clearfix {
	&:after {
		clear: both;
  	display: table;
	}
}
