h6 {
  &.label {
    @include main;
    font-size: 16.5px;
    font-weight: 400;
    line-height: 16.5px;
    margin: 0 0 25px;
    @include media($w768) {
      font-size: 15px;
      line-height: 15px;
      margin: 0 0 17px;
    }
    &:before {
      @include translateY;
      background-color: transparent;
      width: 55px;
      height: 2px;
      display: block;
      position: absolute;
      top: calc(50% + 1px);
      right: calc(100% + 15px);
      @include media($w1400) {
        display: none;
      }
    }
  }
}

h3 {
  &.section-title {
    font-size: 44px;
    font-weight: 400;
    line-height: 53px;
    margin: 0 0 30px;
    @include media($w1400) {
      font-size: 36px;
      line-height: 42px;
    }
    @include media($w1024) {
      font-size: 32px;
      line-height: 38px;
    }
    @include media($w768) {
      font-size: 28px;
      line-height: 34px;
      margin: 0 0 20px;
    }
    @include media($w560) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
