.kv {
  @include main;
  background-size: cover;
  background-position: center;
  padding: 115px 0;
  z-index: 25;
  @include media($w1400) {
    padding: 95px 0;
  }
  @include media($w1024) {
    padding: 65px 0;
  }
  @include media($w768) {
    padding: 50px 0;
  }
  @include media($w560) {
    padding: 30px 0;
  }
  &__inner {
    background-color: $lead;
    width: 470px;
    color: $white;
    margin-right: calc((100% - 1230px) / 2);
    padding: 65px 50px 80px;
    position: relative;
    float: right;
    @include media($w1400) {
      width: 440px;
      padding: 55px 40px;
    }
    @include media($w1290) {
      margin-right: 30px;
    }
    @include media($w1024) {
      width: 390px;
      padding: 40px 30px 45px;
    }
    @include media($w768) {
      background-color: rgba($lead,0.9);
      width: calc(100% - 60px);
      margin: 0 30px;
      padding: 30px 30px 35px;
    }
    @include media($w560) {
      padding: 30px 30px 33px;
    }
    &:after {
      border-top: 55px solid $lead;
      border-left: 55px solid transparent;
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      @include media($w1400) {
        border-top-width: 45px;
        border-left-width: 45px;
      }
      @include media($w1024) {
        border-top-width: 35px;
        border-left-width: 35px;
      }
      @include media($w768) {
        border-top-width: 25px;
        border-top-color: rgba($lead,0.9);
        border-left-width: 25px;
      }
      @include media($w560) {
        border-top-width: 15px;
        border-left-width: 15px;
      }
    }
    h1 {
      font-size: 40.5px;
      font-weight: 400;
      line-height: 38.5px;
      margin: 0 0 35px;
      @include media($w1400) {
        font-size: 36px;
        line-height: 36px;
      }
      @include media($w1024) {
        font-size: 32px;
        line-height: 32px;
        margin: 0 0 32px;
      }
      @include media($w768) {
        font-size: 28px;
        line-height: 28px;
        margin: 0 0 27px;
      }
      @include media($w560) {
        font-size: 24px;
        line-height: 24px;
        margin: 0 0 25px;
      }
      strong {
        font-weight: 600;
      }
    }
    p {
      font-size: 15px;
      margin: 0 0 40px;
      @include media($w1024) {
        margin: 0 0 32px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
    }
    ul {
      li {
        width: auto;
        font-size: 16px;
        line-height: 21px;
        padding: 0 20px 0 0;
        @include media($w1400) {
          font-size: 15px;
          line-height: 20px;
        }
        @include media($w1024) {
          font-size: 14px;
          line-height: 19px;
        }
        &:after {
          content: '/';
          display: block;
          position: absolute;
          top: -1.5px;
          left: calc(100% - 11.5px);
        }
        &:last-child {
          padding: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
