.break {
  @include main;
  background-image: url(../images/break--background.jpg);
  background-size: cover;
  background-position: center;
  height: 390px;
  @include media($w1400) {
    height: 350px;
  }
  @include media($w1024) {
    height: 300px;
  }
  @include media($w768) {
    height: 250px;
  }
  @include media($w560) {
    height: 200px;
  }
  img {
    @include translate;
    width: 224px;
    top: calc(50% + 8px) !important;
    @include media($w1024) {
      width: 210px;
      top: calc(50% + 7px) !important;
    }
    @include media($w768) {
      width: 200px;
      top: calc(50% + 6px) !important;
    }
    @include media($w560) {
      width: 190px;
      top: calc(50% + 5px) !important;
    }
  }
}
