input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
	@include main;
	@include transition;
	border: 1px solid $black;
	border-radius: 0;
	background-color: transparent;
	font-family: '';
	font-size: 16px;
	line-height: 16px;
	color: $black;
	margin: 0;
	padding: 12px 15px 13px;
	appearance: none;
	&:focus {
		border-color: $black;
		background-color: transparent;
	}
}

input[type="submit"] {
	appearance: none;
}

textarea {
	height: 120px;
	padding: 12px 15px 14px;
}

::-webkit-input-placeholder {
	color: $black !important;
	opacity: 1;
}

:-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

::-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $black !important;
	opacity: 1;
}

button {
	@include transition;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	appearance: none;
}
