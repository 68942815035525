.header {
  @include transition;
  background-color: $white;
  width: 100%;
  text-align: center;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  .container {
    @include transition;
    height: 175px;
    @include media($w1400) {
      height: 130px;
    }
    @include media($w1024) {
      height: 100px;
    }
    @include media($w768) {
      height: 80px;
    }
    @include media($w560) {
      height: 70px;
    }
  }
  &__logo {
    @include translateY;
    width: 183px;
    top: calc(50% + 2px) !important;
    float: left;
    @include media($w1400) {
      width: 164px;
    }
    @include media($w1024) {
      top: calc(50% + 4px) !important;
    }
    @include media($w768) {
      width: 158px;
    }
    img {
      width: 100%;
    }
  }
  &__menu {
    @include translateY;
    display: inline-block;
    z-index: 50;
    @include media($w1024) {
  		@include translateReset;
      @include transition;
      background-color: $lead;
      width: 100%;
      height: 100vh;
      pointer-events: none;
      opacity: 0;
      position: fixed;
      top: -100vh;
      left: 0;
  	}
    ul {
      width: auto;
      @include media($w1024) {
        @include translateY;
        width: 100%;
    		text-align: center;
    	}
      li {
        width: auto;
        font-size: 18px;
        font-weight: 500;
        @include media($w1400) {
          font-size: 17px;
        }
        @include media($w1024) {
      		width: 100%;
          font-size: 16px;
      	}
        & + li {
          margin: 0 0 0 20px;
          @include media($w1024) {
        		margin: 15px 0 0;
        	}
        }
        a {
          color: $lead;
          @include media($w1024) {
        		color: $white;
        	}
        }
        &.current {
          a {
            font-weight: 600;
          }
        }
      }
    }
    &.menu-visible {
      pointer-events: auto;
      opacity: 1;
      top: 0;
    }
  }
  &__options {
    @include translateY;
    width: auto;
    float: right;
    @include media($w1024) {
      margin: 0 40px 0 0;
    }
    li {
      width: auto;
      & + li {
        margin: 0 0 0 5px;
      }
      img {
        width: 32px;
        @include media($w1024) {
          width: 30px;
        }
        @include media($w768) {
          width: 28px;
        }
      }
    }
  }
  &__trigger {
    @include transition;
    background-color: transparent;
		width: 20px;
		height: 15px;
		opacity: 1;
		cursor: pointer;
		pointer-events: auto;
		overflow: hidden;
		display: none;
		position: absolute;
    z-index: 150;
		top: 42px;
    right: 30px;
    @include media($w1024) {
      display: block;
    }
    @include media($w768) {
      top: 33px;
    }
    @include media($w560) {
      top: 28px;
    }
		span {
      @include transition;
			background-color: $lead;
			width: 100%;
			height: 3px;
      display: block;
			position: absolute;
			top: 6px;
			left: 0;
			&:before,
			&:after {
        @include transition;
				background-color: $lead;
				width: 100%;
				height: 3px;
				display: block;
				position: absolute;
				left: 0;
			}
			&:before {
				top: -6px;
			}
			&:after {
				bottom: -6px;
			}
		}
		&.menu-visible {
			position: fixed;
			right: 30px;
			span {
        background-color: transparent;
				&:before,
				&:after {
					background-color: $white;
					top: 0;
				}
				&:before {
				  transform: rotate(-45deg);
				}
				&:after {
				  transform: rotate(45deg);
				}
			}
		}
  }
  &.scrolling {
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.08);
    .container {
      height: 130px;
      @include media($w1400) {
        height: 100px;
      }
      @include media($w1024) {
        height: 90px;
      }
      @include media($w768) {
        height: 80px;
      }
      @include media($w560) {
        height: 70px;
      }
    }
    .header__trigger {
  		top: 37px;
      @include media($w768) {
        top: 33px;
      }
      @include media($w560) {
        top: 28px;
      }
    }
  }
}
