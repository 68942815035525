.footer {
  @include main;
  text-align: center;
  &__inner {
    border-top: 1px solid $lead;
    padding: 50px 0;
    @include media($w560) {
      padding: 40px 0;
    }
    p {
      font-size: 14.5px;
      line-height: 19.5px;
      margin: 0 0 22px;
      @include media($w560) {
        margin: 0 0 17px;
      }
    }
    a {
      color: $black;
      &:hover {
        color: $lead;
      }
    }
    & > *:last-child {
      margin: 0;
    }
  }
  &__rules {
    font-size: 16.5px;
    font-weight: 600;
    margin: 0 0 27px;
    display: inline-block;
    @include media($w768) {
      font-size: 14.5px;
      line-height: 19.5px;
    }
    @include media($w560) {
      margin: 0 0 17px;
    }
  }
}
